<template>
	<font></font>
</template>

<script>
import { USER_CLEAR } from '@/store/actions/user';
import { AUTH_LOGOUT } from '@/store/actions/auth';

export default {
	beforeCreate () {
		this.$store.dispatch(USER_CLEAR);
		this.$store.dispatch(AUTH_LOGOUT).then(() => {
			if( this.$route.name != '' )this.$router.push('/');
		});
	},
}
</script>